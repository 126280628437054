import { DragDropContext } from "@hello-pangea/dnd";
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fuse from 'fuse.js';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { ConfirmLoanDropDialog } from "src/components/loans/confirm-loan-drop-dialog";
import { KeyStorage } from "src/constants/key-storage";
import { BorrowerListViewColumnsDefaultOrder, LoanListViewColumnsDefaultOrder, LoanSearchAttributes, LoansKanbanFilter, LoanStageCategory } from "src/constants/loan";
import { VirtualLoanPhase } from "src/constants/loan-phase";
import { LOAN_SORTED_IDS } from "src/constants/local-storage";
import { OrderDirection } from "src/constants/ui";
import { Permission } from "src/constants/user";
import { useKeyStorage } from "src/hooks/use-key-storage";
import { useLoanStages } from "src/hooks/use-loan-stages";
import { useLoans } from "src/hooks/use-loans";
import { useLocalStorage } from "src/hooks/use-local-storage";
import { useUser } from "src/hooks/use-user";
import { usePinLoanMutation, useUnPinLoanMutation } from "src/services/loanApi";
import { updateLoanPhase } from "src/slices/loan";
import { loansFilterSelector, uiActions } from "src/slices/ui";
import { useDispatch, useSelector } from "src/store";
import { LoanPhaseDto } from "src/types/api";
import { ILoanStatus, Loan, LoanDisplayView, LoanSortBy } from "src/types/loan";
import { filterKanbanLoans } from "src/utils/loan/filter-kanban-loans";
import { stableSort } from "src/utils/stable-sort";
import { toast } from "src/utils/toast";
import { roleCan } from "src/utils/user/role-can";

import { getComparator } from "./loans-kanban-utils";

type LoansFilterType = keyof typeof LoansKanbanFilter;

interface DropState {
    destinationPhase: LoanPhaseDto;
    sourcePhaseId: ILoanStatus;
    prevSourceLoans: Loan[];
    prevDestinationLoans: Loan[];
    nextSourceLoans: Loan[];
    nextDestinationLoans: Loan[];
    loan: Loan;
}
export interface State {
    loans: Record<string, Loan[]>;
    isLoading: boolean;
    statuses: LoanPhaseDto[];
    isDropConfirmationOpen: boolean;
    dropState?: DropState;
    displayView: LoanDisplayView;
    sortBy: LoanSortBy;
    sortedListViewColumns: string[];
    orderDirection: OrderDirection;
    orderByColumn: string | null;
    isLender: boolean;
};

const initialState = {
    loans: null,
    isLender: false,
    isLoading: false,
    statuses: [],
    isDropConfirmationOpen: false,
    displayView: LoanDisplayView.grid,
    sortBy: LoanSortBy.newest,
    sortedListViewColumns: [],
    orderDirection: OrderDirection.Desc,
    orderByColumn: null,
};

type Action = { type: 'SET_LOANS', payload: Record<string, Loan[]> }
    | { type: 'SET_LOANS_DISPLAY_VIEW', payload: LoanDisplayView }
    | { type: 'SET_LOANS_SORT_BY', payload: LoanSortBy }
    | { type: 'SET_DROP_CONFIRMATION_STATE', payload: boolean, dropState: DropState }
    | { type: 'RESTORE_PREVIOUS_STATE' }
    | { type: 'SET_STATUSES', payload: LoanPhaseDto[] };

type Dispatch = (action: Action) => void

export interface LoanKanbanFilterState {
    loansFilter: LoansFilterType,
    teamMembersIds: string[],
    filterQuery: string
}

const KanbanLoansContext = createContext<{
    state: State;
    filterState: LoanKanbanFilterState,
    fuzzySearchLoans: Loan[];
    dispatch: Dispatch,
    onPinLoan: (loanId: string) => void,
    onSortLoans: (sortBy: LoanSortBy) => void,
    onSortByColumn: (column: string) => void,
    onLoansDisplayViewChange: (view: LoanDisplayView) => void,
    onSetSortedListColumns: (columns: string[]) => void,
    onLoansFilterChange: (loansFilter: LoansFilterType, loansFilterQuery: string, teamMembersIds?: string[]) => void
} | undefined>(undefined);

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'SET_LOANS':
            return {
                ...state,
                loans: action.payload
            };
        case 'RESTORE_PREVIOUS_STATE':
            return {
                ...state,
                loans: {
                    ...state.loans,
                    [state.dropState.sourcePhaseId]: state.dropState.prevSourceLoans,
                    [state.dropState.destinationPhase.id]: state.dropState.prevDestinationLoans
                },
                isDropConfirmationOpen: false
            };
        case 'SET_STATUSES':
            return {
                ...state,
                statuses: action.payload
            };
        case 'SET_DROP_CONFIRMATION_STATE':
            return {
                ...state,
                isDropConfirmationOpen: action.payload,
                dropState: action.dropState
            };
        case 'SET_LOANS_DISPLAY_VIEW':
            return {
                ...state,
                displayView: action.payload
            };
        case 'SET_LOANS_SORT_BY':
            return {
                ...state,
                sortBy: action.payload
            };
        default:
            return state;
    }

}

// D&D
// a little function to help us with reordering the result
const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const sortLoans = (a: string, b: string, ids: string[]) => {
    const aIndex = ids.indexOf(a);
    const bIndex = ids.indexOf(b);
    return aIndex - bIndex;
}

const getKanbanSortedLoans = (
    ids: string[],
    loans: Loan[]): Loan[] => {
    const pinnedLoans = loans.filter(loan => loan.pinned);
    const unpinnedLoans = loans.filter(loan => !loan.pinned);
    const sortedUnpinnedLoans = unpinnedLoans?.sort((a, b) => sortLoans(a.id, b.id, ids));
    return [...pinnedLoans, ...sortedUnpinnedLoans];
}

const sortListAsc = (a: Loan, b: Loan) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()

const sortListDesc = (a: Loan, b: Loan) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()

const getListSortedLoans = (loans: Loan[], sortBy: LoanSortBy, orderDirection: OrderDirection, orderBy: string): Loan[] => {
    let sortedLoans = loans.sort(sortBy === LoanSortBy.newest ? sortListAsc : sortListDesc);
    if (orderDirection && orderBy) {
        sortedLoans = stableSort(loans, getComparator(orderDirection, orderBy))
    }
    const pinnedLoans = sortedLoans.filter(loan => loan.pinned);
    const unpinnedLoans = sortedLoans.filter(loan => !loan.pinned);

    return [...pinnedLoans, ...unpinnedLoans];
};

type ProviderProps = {
    children: React.ReactNode;
    companyId: string;
    phaseCategory: string;
}

const fuseOptions = {
    useExtendedSearch: true,
    findAllMatches: true,
    keys: LoanSearchAttributes
};

const KanbanLoansProvider = ({ children, companyId, phaseCategory }: ProviderProps) => {
    const { allLoans } = useLoans();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [pinLoan] = usePinLoanMutation();
    const [unpinLoan] = useUnPinLoanMutation();
    const { user, isUnderwriter, isLender, isBorrower } = useUser();

    const {
        value: remoteSettings,
        setValue: saveRemoteSettings,
        isLoading: isLoadingRemoteSettings } = useKeyStorage<Pick<State, 'displayView' | 'sortBy' | 'sortedListViewColumns' | 'orderDirection' | 'orderByColumn'>>(KeyStorage.KanbanSetting, {
            displayView: LoanDisplayView.grid,
            sortBy: LoanSortBy.newest,
            orderDirection: OrderDirection.Asc,
            orderByColumn: null,
            columnsOrder: isBorrower ? BorrowerListViewColumnsDefaultOrder : LoanListViewColumnsDefaultOrder
        });

    const [storedIds, setStoredIds] = useLocalStorage<string[]>(LOAN_SORTED_IDS, []);
    const reduxDispatch = useDispatch();
    const filterState = useSelector(loansFilterSelector);

    const userId = user?.id;
    const [state, dispatch] = useReducer(reducer, { ...initialState })
    const {
        originationPhases,
        portfolioPhases,
        leadPhases,
        archivePhases
    } = useLoanStages({ companyId });

    // set phases value based on phaseCategory
    const phases = useMemo(() => {
        switch (phaseCategory) {
            case LoanStageCategory.ORIGINATION:
                return isBorrower ? [VirtualLoanPhase.ORIGINATION] : originationPhases;
            case LoanStageCategory.LEAD:
                return isBorrower ? [VirtualLoanPhase.ORIGINATION] : leadPhases;
            case LoanStageCategory.PORTFOLIO:
                return isBorrower ? [VirtualLoanPhase.ORIGINATION] : portfolioPhases;
            case LoanStageCategory.ARCHIVE:
                return isBorrower ? [VirtualLoanPhase.ORIGINATION] : archivePhases;
            default:
                return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originationPhases.length, portfolioPhases.length, archivePhases.length, phaseCategory]);

    const handleDragEnd = (result) => {
        const loan = allLoans.find(loan => loan.id === result.draggableId);
        const userRoleOnLoan = loan?.loanRoles?.find(userRole => userRole.user.id === userId);

        if (result.destination) {
            // dropped in the same column
            if (result.source.droppableId === result.destination.droppableId) {
                const sourceLoansList: Loan[] = state.loans[result.source.droppableId];

                const defaultSorting = sourceLoansList.map(loan => loan.id);
                const sortedIds = reorder(
                    defaultSorting,
                    result.source.index,
                    result.destination.index
                );
                const reSortedLoans = getKanbanSortedLoans(
                    sortedIds,
                    sourceLoansList
                );
                const resortedIds = sortedIds.sort((a, b) => sortLoans(a, b, sortedIds));
                setStoredIds(resortedIds);
                dispatch({
                    type: 'SET_LOANS',
                    payload: {
                        ...state.loans,
                        [result.source.droppableId as ILoanStatus]: reSortedLoans
                    }
                });
            }
            // dropped in a different column 
            else if (roleCan(userRoleOnLoan?.role, Permission.ChangeLoanPhase)) {
                const sourcePhaseId = result.source.droppableId as ILoanStatus;
                const destinationPhaseId = result.destination.droppableId as ILoanStatus;
                const destinationPhase = phases.find(phase => phase.id === destinationPhaseId);

                const movedList = move(
                    state.loans[sourcePhaseId] ?? [],
                    state.loans[destinationPhaseId] ?? [],
                    result.source,
                    result.destination
                );

                const sourceReSortedLoans = getKanbanSortedLoans([], movedList[sourcePhaseId]);
                const destinationReSortedLoans = getKanbanSortedLoans([], movedList[destinationPhaseId]);

                const sourceLoansIds = sourceReSortedLoans.map(loan => loan.id);
                const destinationLoansIds = destinationReSortedLoans.map(loan => loan.id);
                const sortedIds = [...sourceLoansIds, ...destinationLoansIds, ...storedIds];
                setStoredIds(sortedIds);
                const loan = allLoans.find(loan => loan.id === result.draggableId);
                dispatch({
                    type: 'SET_DROP_CONFIRMATION_STATE', payload: true,
                    dropState: {
                        destinationPhase,
                        loan,
                        sourcePhaseId,
                        prevSourceLoans: state.loans[sourcePhaseId],
                        prevDestinationLoans: state.loans[destinationPhaseId],
                        nextDestinationLoans: destinationReSortedLoans,
                        nextSourceLoans: sourceReSortedLoans
                    }
                });
                const payload = {
                    ...state.loans,
                    [sourcePhaseId]: sourceReSortedLoans,
                    [destinationPhaseId]: destinationReSortedLoans
                };

                dispatch({ type: 'SET_LOANS', payload });
            }

        }
    }
    const handleLoansFilterChange = useCallback((loansFilter: LoansFilterType, filterQuery: string, teamMembersIds: string[] = []) => {
        reduxDispatch(uiActions.setLoansFilter({
            loansFilter,
            filterQuery,
            teamMembersIds
        }));
    }, [reduxDispatch]);

    const handlePinLoanClick = useCallback((loanId: string) => {
        const loan = allLoans.find(loan => loan.id === loanId);
        if (loan.pinned) {
            unpinLoan({ loanId });
        } else {
            pinLoan({ loanId });
        }
    }, [allLoans, pinLoan, unpinLoan]);


    const handleConfirmDrop = async () => {
        const updated = await reduxDispatch(updateLoanPhase({
            loanId: state.dropState.loan.id,
            phaseId: state.dropState.destinationPhase.id
        }));

        if (updated === false) {
            dispatch({ type: 'RESTORE_PREVIOUS_STATE' });
        } else {
            dispatch({ type: 'SET_DROP_CONFIRMATION_STATE', payload: false, dropState: null });
        }
    }

    const handleLoansDisplayViewChange = useCallback((loansDisplayView: LoanDisplayView) => {
        saveRemoteSettings({
            ...remoteSettings,
            displayView: loansDisplayView,
        }).catch(() => {
            toast({
                content: 'Failed to save settings',
                type: 'error'
            })
        });
    }, [remoteSettings, saveRemoteSettings]);

    const handleConfirmDropClose = () => {
        dispatch({ type: 'RESTORE_PREVIOUS_STATE' });
    }

    const handleLoanSortByChange = useCallback((sortBy: LoanSortBy) => {
        saveRemoteSettings({
            ...remoteSettings,
            sortBy: sortBy,
            orderByColumn: null,
            orderDirection: OrderDirection.Asc,
        }).catch(() => {
            toast({
                content: 'Failed to save settings',
                type: 'error'
            })
        })
    }, [remoteSettings, saveRemoteSettings]);

    const handleSortByColumn = useCallback((columnId: string) => {
        saveRemoteSettings({
            ...remoteSettings,
            sortBy: null,
            orderByColumn: columnId,
            orderDirection: remoteSettings.orderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        }).catch(() => {
            toast({
                content: 'Failed to save settings',
                type: 'error'
            })
        })
    }, [remoteSettings, saveRemoteSettings]);

    const handleSetSortedListColumns = useCallback((columns: string[]) => {
        saveRemoteSettings({
            ...remoteSettings,
            sortedListViewColumns: columns,
        }).catch(() => {
            toast({
                content: 'Failed to save settings',
                type: 'error'
            })
        })
    }, [remoteSettings, saveRemoteSettings]);

    const loansOrPhasesUpdated = allLoans.reduce((acc: string, loan) => {
        return acc + loan.loanPhase?.id + loan.pinned + loan.id;
    }, '');

    const fuzzySearchLoans = useMemo(() => {
        if (filterState.filterQuery) {
            // do fuzzy search on the loan roles to find the matching users
            const fuse = new Fuse(allLoans, fuseOptions);

            const searchResult = fuse.search<Loan>(`'${filterState.filterQuery}`);
            return searchResult.map(result => result.item);
        } else {
            return allLoans;
        }
    }, [allLoans, filterState.filterQuery])

    useEffect(() => {
        if (fuzzySearchLoans.length) {
            const allFilteredLoans = fuzzySearchLoans
                .filter(loan => filterKanbanLoans({
                    loan,
                    isUserABorrower: isBorrower,
                    isUnderwriter,
                    filterState,
                    userId,
                }));
            // sort loans by sortBy
            const groupedLoansByStatus = allFilteredLoans.reduce((acc, loan) => {
                const phaseId = isBorrower ? VirtualLoanPhase.ORIGINATION.id : loan.loanPhase?.id;
                return { ...acc, [phaseId]: [...(acc[phaseId] || []), loan] };
            }, {} as Record<ILoanStatus, Loan[]>);

            const groupedSortedLoansByStatus = Object.keys(groupedLoansByStatus ?? {}).reduce((all, status) => {
                const statusLoans = groupedLoansByStatus[status];
                let sortedLoans = [];
                if (remoteSettings.displayView === LoanDisplayView.grid) {
                    sortedLoans = getKanbanSortedLoans(
                        storedIds,
                        statusLoans?.length > 0 ? statusLoans : []
                    )
                } else {
                    sortedLoans = getListSortedLoans(
                        statusLoans?.length > 0 ? statusLoans : [],
                        remoteSettings.sortBy,
                        remoteSettings.orderDirection,
                        remoteSettings.orderByColumn,
                    )
                }
                return ({ ...all, [status]: sortedLoans });
            }, {});

            dispatch({ type: 'SET_LOANS', payload: groupedSortedLoansByStatus });
        }

        dispatch({
            type: 'SET_STATUSES',
            payload: phases
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        loansOrPhasesUpdated,
        phases.length,
        phaseCategory,
        filterState.filterQuery,
        filterState.loansFilter,
        filterState.teamMembersIds?.length,
        userId,
        isUnderwriter,
        remoteSettings.sortBy,
        remoteSettings.displayView,
        storedIds.length,
        remoteSettings.orderDirection,
        remoteSettings.orderByColumn,
        isBorrower]);

    const value = useMemo(() => ({
        state: {
            ...state,
            isLoading: isLoadingRemoteSettings,
            displayView: smDown ? LoanDisplayView.grid : remoteSettings.displayView,
            sortBy: remoteSettings.sortBy,
            sortedListViewColumns: remoteSettings.sortedListViewColumns,
            orderByColumn: remoteSettings.orderByColumn,
            orderDirection: remoteSettings.orderDirection,
            isLender,
        },
        fuzzySearchLoans,
        filterState,
        dispatch,
        onLoansDisplayViewChange: handleLoansDisplayViewChange,
        onPinLoan: handlePinLoanClick,
        onSortLoans: handleLoanSortByChange,
        onLoansFilterChange: handleLoansFilterChange,
        onSetSortedListColumns: handleSetSortedListColumns,
        onSortByColumn: handleSortByColumn
    }), [state, isLoadingRemoteSettings, smDown, remoteSettings.displayView, remoteSettings.sortBy, remoteSettings.sortedListViewColumns, remoteSettings.orderByColumn, remoteSettings.orderDirection, isLender, fuzzySearchLoans, filterState, handleLoansDisplayViewChange, handlePinLoanClick, handleLoanSortByChange, handleLoansFilterChange, handleSetSortedListColumns, handleSortByColumn]);

    return <KanbanLoansContext.Provider value={value}>
        <DragDropContext
            onDragEnd={handleDragEnd}>
            {children}
        </DragDropContext>
        <ConfirmLoanDropDialog
            phaseName={state.dropState?.destinationPhase.name}
            loan={state.dropState?.loan}
            isConfirmOpen={state.isDropConfirmationOpen}
            onConfirm={handleConfirmDrop}
            onClose={handleConfirmDropClose} />
    </KanbanLoansContext.Provider>
}

function useKanbanLoans() {
    const context = useContext(KanbanLoansContext)
    if (context === undefined) {
        throw new Error('useKanbanLoans must be used within a KanbanLoansProvider')
    }
    return context
}

export { KanbanLoansProvider, useKanbanLoans }
