import { useEffect } from "react";
import { setNavbarTitle } from "src/slices/ui";
import { useDispatch } from "src/store";

export const useNavbarTitle = (title: string) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setNavbarTitle(title));

        return () => {
            dispatch(setNavbarTitle(""));
        }
    }, [dispatch, title]);
}