import FullscreenExit from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from 'components/dist/atoms/Badge';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { LoanPhaseDto } from "src/backend"
import { DragIcon } from 'src/icons/drag-icon';

import { LoanListViewStyles } from "../loans/loan-list-view/loan-list-view.styles"

interface LoansKanbanPhaseHeaderProps {
    loanPhase: LoanPhaseDto
    isFocused: boolean
    focusMode: boolean
    totalLoans: number
    onFocus: (id: string | null) => void

}

export const LoansKanbanPhaseHeader = (props: LoansKanbanPhaseHeaderProps) => {

    const handleToggle = () => {
        props.onFocus(props.isFocused ? null : props.loanPhase.id)
    }


    return <Stack
        space="sm"
        row
        className={`
            ${props.focusMode ? (props.isFocused ? '' : 'hidden ') : "min-w-[356px]  flex"}
            cursor-pointer rounded-t-md h-full bg-white-neutral
             items-center p-3 w-full
        `}
        onDoubleClick={handleToggle} >
        <Badge variant="secondary" circle>{props.totalLoans}</Badge>
        <Text
            weight="medium"
            size="sm"
            className="flex-1"
            data-testid={`kanban-column-title-${props.loanPhase.name}`}
            component='div'
        >
            {props.loanPhase.name}
        </Text>
        {props.isFocused && <Tooltip title='Restore' arrow disableInteractive>
            <IconButton
                onClick={handleToggle}
                color='inherit'>
                <FullscreenExit />
            </IconButton>
        </Tooltip>}
        {!props.isFocused && <Tooltip title='Maximize' arrow disableInteractive>
            <IconButton onClick={handleToggle} color='inherit'>
                <DragIcon />
            </IconButton>
        </Tooltip>}
    </Stack>
}