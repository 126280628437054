import * as React from "react"

export const Table2ColumnsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2 11.5h12m-12-3h12m0-3H2m6 9v-12M2 14.1V2.9a.4.4 0 0 1 .4-.4h11.2a.4.4 0 0 1 .4.4v11.2a.4.4 0 0 1-.4.4H2.4a.4.4 0 0 1-.4-.4Z"
    />
  </svg>
)
