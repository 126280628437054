import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { themeV2 } from "src/theme/mysherpas-theme-option";

export const MenuButton = styled(MuiButton)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    padding: theme.spacing(.4, 2),
    color: themeV2.colors.blue[900],
    '&.MuiButton-outlined': {
        borderColor: themeV2.neutral.grey[80],
        '&[data-active="true"]': {
            backgroundColor: themeV2.neutral.grey[10],
            color: themeV2.neutral.grey[90],
        },
    },
    '&.MuiButton-containedPrimary': {
        backgroundColor: themeV2.colors.blue[100],
        color: 'white',
        '& .MuiCircularProgress-svg': {
            color: 'white',
        }
    }

}))
