import { LoanPhaseDto } from "src/backend";

const originationPhase: LoanPhaseDto = {
    id: 'ORIGINATION',
    name: 'Origination',
    category: 'ORIGINATION',
    displayOrder: 0,
    lenderId: 'dummy',
}

const leadPhase: LoanPhaseDto = {
    id: 'LEAD',
    name: 'Lead',
    category: 'LEAD',
    displayOrder: 0,
    lenderId: 'dummy',
}

const portfolioPhase: LoanPhaseDto = {
    id: 'PORTFOLIO',
    name: 'Portfolio',
    category: 'PORTFOLIO',
    displayOrder: 0,
    lenderId: 'dummy',
}

const archivePhase: LoanPhaseDto = {
    id: 'ARCHIVE',
    name: 'Archive',
    category: 'ARCHIVE',
    displayOrder: 0,
    lenderId: 'dummy',
}

export const VirtualLoanPhase = {
    ORIGINATION: originationPhase,
    LEAD: leadPhase,
    PORTFOLIO: portfolioPhase,
    ARCHIVE: archivePhase,
} as const;