import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import type { PopperProps } from '@mui/material/Popper';
import * as React from 'react';
import { NavArrowDownIcon } from 'src/icons/nav-arrow-down-icon';

import { FormikedTextFieldStyles } from '../common/form/formiked-fields/formiked-text-field.styles';
import { MenuButton } from '../common/menu-button';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
    options: { id: string, label: string }[];
    onChange: (event: React.ChangeEvent<{}>, value: { id: string, label: string }[]) => void;
    value: { id: string, label: string }[];
}

export const TeammatesSearchCheckbox = React.memo((props: Props) => {
    const { options, onChange, value } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <MenuButton
                sx={{ backgroundColor: 'white' }}
                endIcon={<NavArrowDownIcon />} onClick={handleClickListItem}>
                Select Team Member
            </MenuButton>
            <Menu
                id="select-team-member-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'select-team-member',
                    role: 'listbox',
                    sx: {
                        py: 0,
                        minWidth: {
                            xs: 300,
                        }
                    }
                }}
            >
                <Autocomplete
                    open
                    value={value}
                    disableClearable
                    popupIcon={null}
                    ChipProps={{
                        sx: {
                            display: 'none',
                            visibility: 'hidden',
                        }
                    }}
                    PopperComponent={PopperComponent}
                    multiple
                    onChange={onChange}
                    id="teammates-search-checkbox"
                    options={options}
                    disableCloseOnSelect
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label}

                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <FormikedTextFieldStyles.TextField
                            {...params}
                            size='small'
                            autoFocus
                            placeholder="Search"
                            sx={{ px: 2, pt: 2 }} />
                    )}
                />
            </Menu>
        </>
    );
});

TeammatesSearchCheckbox.displayName = 'TeammatesSearchCheckbox';

const PopperComponent = ({ children, ...props }: React.PropsWithChildren<PopperProps>) => (
    <Box
        {...props}
        key='teammates-popper'
        width={undefined}
        sx={{
            '&.MuiAutocomplete-popper': {
                width: '100% !important',
            },
            '& .MuiAutocomplete-listbox': {
                py: 0,
            },
            '& .MuiAutocomplete-paper': {
                boxShadow: 0,
                mt: 1.5,
                borderRadius: 0,
                '& ul': {
                    maxHeight: 300,
                    overflow: 'auto',
                },
            },
        }}
    >
        {children}
    </Box>
)