import MuiCardHeader from '@mui/material/CardHeader';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Wrapper = styled('div')(({
    '&[data-hide-statuses="true"]': {
        '& .loan-ready-for-review-icon-wrapper': {
            display: 'none',
            visibility: 'hidden',
        }
    }
}))

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .MuiCardHeader-title': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        gap: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    }
}))


const Chip = styled(MuiChip)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: themeV2.colors.blue[900],
    minWidth: 20,
    height: 20,
}))

export const LoanListViewStyles = {
    Wrapper,
    CardHeader,
    Chip
}