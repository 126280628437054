import Badge from 'components/dist/atoms/Badge';
import Icon from 'components/dist/atoms/Icon';
import { LoanPhaseCategoryType } from "src/backend"

interface PhaseCategoryChipProps {
    phaseCategory: LoanPhaseCategoryType;
}

export const PhaseCategoryChip = (props: PhaseCategoryChipProps) => {
    let className = '';
    let iconName: 'Origination' | 'Filter' | 'PieChart' = 'Origination' as const;
    if (props.phaseCategory === 'LEAD') {
        className = 'bg-yellow-5 text-yellow-90';
        iconName = 'Filter';
    } else if (props.phaseCategory === 'ORIGINATION') {
        className = 'bg-blue-10 text-blue-100';
    } else if (props.phaseCategory === 'PORTFOLIO') {
        className = 'bg-brown-20 text-brown-100';
    } else {
        iconName = 'PieChart';
        className = 'bg-gray-neutral-30 text-black-primary';
    }
    return <Badge
        className={`capitalize gap-1 ${className}`}>
        <Icon name={iconName} width={16} height={16} strokeWidth={2} />
        {props.phaseCategory.toLowerCase()}
    </Badge>
}