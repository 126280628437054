import { LoansKanbanFilter } from "src/constants/loan";
import { LoanKanbanFilterState } from "src/contexts/loans-kanban-context";
import { Loan } from "src/types/loan";

import { isIdALenderOnLoanRole } from "./is-id-lender-on-loan-role";

export const filterKanbanLoans = ({ loan, isUserABorrower, filterState, userId }: { isUnderwriter: boolean, loan: Loan, isUserABorrower: boolean, filterState: LoanKanbanFilterState, userId: string }) => {
    // if user is a borrower
    if (isUserABorrower) {
        return true;
        // show teammates loans
    } else if (filterState.loansFilter === LoansKanbanFilter.TEAMMATES) {
        return loan.loanRoles.some(role => filterState.teamMembersIds.includes(role.user.id));
        // show my loans
    } else if (filterState.loansFilter === LoansKanbanFilter.LENDER_LOANS) {
        return loan.loanRoles.some(role => isIdALenderOnLoanRole(userId, role));
        // show pinned loans
    } else if (filterState.loansFilter === LoansKanbanFilter.PINNED) {
        return loan.pinned;
    } else if (filterState.loansFilter === LoansKanbanFilter.UNPINNED) {
        // show unpinned loans
        return !loan.pinned;
    }

    return true;
}