import * as React from 'react';
import { KeyboardKey } from 'src/constants/ui';
import useEventListener from 'src/hooks/use-event-listener';
import { AcceptAgreeGraphic } from "src/icons/accept-agree-graphic";
import { Loan } from 'src/types/loan';

import { GenericConfirmationDialog } from '../common/generic-confirmation-dialog';

interface Props {
    isConfirmOpen: boolean;
    phaseName: string;
    loan: Loan;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export const ConfirmLoanDropDialog = (props: Props) => {

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === KeyboardKey.ENTER && props.isConfirmOpen) {
            props.onConfirm?.().catch((error) => {
                console.error(error);
            });
        }
    }

    useEventListener('keydown', handleKeyDown);

    return <GenericConfirmationDialog
        onCancel={props.onClose}
        onConfirm={props.onConfirm}
        open={props.isConfirmOpen}
        title={<>Confirm moving {props.loan?.shortCode} {props.loan?.projectName} from <span style={{ textTransform: 'capitalize' }}>{props.loan?.loanPhase?.name}</span> to <span style={{ textTransform: 'capitalize' }}>{props.phaseName}</span>?</>}
        message={''}>
        <AcceptAgreeGraphic />
    </GenericConfirmationDialog>
}