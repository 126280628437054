import { getLoanColumnsWithValues } from "src/components/loans/loan-list-view/loan-list-view-table-row.hook";
import { LoanListViewColumns } from "src/constants/loan"
import { OrderDirection } from "src/constants/ui";
import { Loan } from "src/types/loan";

export function descendingComparator<T>(a: T, b: T, orderBy: string) {
    const aColumnsWithValues = getLoanColumnsWithValues({
        loan: a as Loan,
        columns: LoanListViewColumns
    })
    const bColumnsWithValues = getLoanColumnsWithValues({
        loan: b as Loan,
        columns: LoanListViewColumns
    })
    const aColumn = aColumnsWithValues.find(column => column.id === orderBy)?.value
    const bColumn = bColumnsWithValues.find(column => column.id === orderBy)?.value


    if (bColumn < aColumn) {
        return -1;
    }
    if (bColumn > aColumn) {
        return 1;
    }
    return 0;
}


export function getComparator<Key extends keyof Loan>(
    order: OrderDirection,
    orderBy: string,
): (
    a: Loan,
    b: Loan,
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
