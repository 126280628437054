import * as React from "react"

export const ViewGridIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.333 14.1v-3.867a.4.4 0 0 1 .4-.4H13.6a.4.4 0 0 1 .4.4V14.1a.4.4 0 0 1-.4.4H9.733a.4.4 0 0 1-.4-.4ZM2 14.1v-3.867a.4.4 0 0 1 .4-.4h3.867a.4.4 0 0 1 .4.4V14.1a.4.4 0 0 1-.4.4H2.4a.4.4 0 0 1-.4-.4Zm7.333-7.333V2.9a.4.4 0 0 1 .4-.4H13.6a.4.4 0 0 1 .4.4v3.867a.4.4 0 0 1-.4.4H9.733a.4.4 0 0 1-.4-.4ZM2 6.767V2.9a.4.4 0 0 1 .4-.4h3.867a.4.4 0 0 1 .4.4v3.867a.4.4 0 0 1-.4.4H2.4a.4.4 0 0 1-.4-.4Z"
    />
  </svg>
)
