import * as React from "react"

export const InfoEmptyIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
        viewBox="0 0 14 14"
        {...props}
    >
        <g clipPath="url(#InfoEmptyIcon)">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M7.001 6.706v2.916m0-5.244.006-.006m-.006 8.459a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.667Z"
            />
        </g>
        <defs>
            <clipPath id="InfoEmptyIcon">
                <path fill="#fff" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
)
