import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from 'components/dist/atoms/Tooltip';
import * as React from 'react';
import { DndProvider } from 'src/providers/dnd-provider';
import { Loan } from 'src/types/loan';

import { useLoanListViewTable } from './loan-list-view-table.hook';
import { LoanListViewTableStyles } from './loan-list-view-table.styles';
import { LoanListViewTableRow } from './loan-list-view-table-row';
import { LoanListViewTableTotalRow } from './loan-list-view-table-total-row';

interface LoanListViewTableProps {
    loans: Loan[];
    displayTotal?: boolean;
}

export const LoanListViewTable = (props: LoanListViewTableProps) => {
    const state = useLoanListViewTable();

    const viewableColumns = state.columns.filter((column) =>
        state.selectedHeaders.find((header) => header.id === column.id));

    return (<TableContainer className='bg-white'>
        <LoanListViewTableStyles.Table aria-label="loans table" data-table-fixed="true">
            <TableHead>
                <DragDropContext onDragEnd={state.onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        direction="horizontal"
                    >
                        {(provided) => (
                            <LoanListViewTableStyles.TableRow
                                data-head="true"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <LoanListViewTableStyles.TableCell
                                    data-limited-width="true">
                                    Info
                                </LoanListViewTableStyles.TableCell>
                                {viewableColumns.map(
                                    (column) => {
                                        // In order to sort with the info
                                        // view elements we need its index
                                        // out of all existent columns
                                        const indexOutOfAllElements = state.columns.findIndex((col) => col.id === column.id);

                                        return (
                                            <Draggable
                                                key={column.id}
                                                draggableId={column.id}
                                                index={indexOutOfAllElements + 1}
                                            >
                                                {(dragProvided) => (
                                                    <LoanListViewTableStyles.TableCell
                                                        ref={
                                                            dragProvided.innerRef
                                                        }
                                                        key={column.id}
                                                        align={column.align || 'left'}
                                                        data-width={viewableColumns.length < 6 ? 'content' : 'full'}
                                                        {...dragProvided.draggableProps}
                                                        {...dragProvided.dragHandleProps}
                                                    >
                                                        <TableSortLabel
                                                            disabled={
                                                                !column.sortable
                                                            }
                                                            sx={{
                                                                justifyContent: (() => {
                                                                    if (column.align === 'right') {
                                                                        return 'end';
                                                                    } else if (column.align === 'center') {
                                                                        return 'center';
                                                                    }
                                                                    return 'start';
                                                                })(),
                                                                '& > svg': {
                                                                    width: '0',
                                                                },
                                                                '&:hover > svg': {
                                                                    width: 'auto',
                                                                },
                                                            }}
                                                            direction={
                                                                state.orderDirection ??
                                                                'asc'
                                                            }
                                                            active={
                                                                state.orderByColumn ===
                                                                column.id
                                                            }
                                                            onClick={() =>
                                                                state.onSortByColumn(
                                                                    column.id
                                                                )
                                                            }
                                                            className="w-full h-6"
                                                        >
                                                            <Tooltip.Provider>
                                                                <Tooltip>
                                                                    <Tooltip.Trigger className='truncate'>
                                                                        {column.label}
                                                                    </Tooltip.Trigger>
                                                                    <Tooltip.Portal>
                                                                        <Tooltip.Content>
                                                                            {column.label}
                                                                        </Tooltip.Content>
                                                                    </Tooltip.Portal>
                                                                </Tooltip>
                                                            </Tooltip.Provider>
                                                        </TableSortLabel>
                                                    </LoanListViewTableStyles.TableCell>
                                                )}
                                            </Draggable>
                                        )
                                    }
                                )}
                                <LoanListViewTableStyles.TableCell />
                                {viewableColumns.length < 6 && (
                                    <LoanListViewTableStyles.TableCell data-limited-width="true" />
                                )}
                                {provided.placeholder}
                            </LoanListViewTableStyles.TableRow>
                        )}
                    </Droppable>
                </DragDropContext>
            </TableHead>
            <TableBody>
                {props.loans?.map((loan) => (
                    <LoanListViewTableRow
                        onPinLoan={state.onPinLoan}
                        columns={viewableColumns}
                        isPinned={loan.pinned}
                        loan={loan}
                        highlightText={state.filterQuery}
                        key={loan.id}
                    />
                ))}
                {state.isLender &&
                    props.displayTotal &&
                    <LoanListViewTableTotalRow
                        loans={props.loans}
                        columns={viewableColumns}
                    />}
            </TableBody>
        </LoanListViewTableStyles.Table>
    </TableContainer>
    );
};
