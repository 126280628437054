import { useGetKeyPreferenceQuery, useSetKeyPreferenceMutation } from "src/services/userApi";

interface ReturnValue<T> {
    value: T;
    isLoading: boolean;
    setValue: (value: T) => Promise<void>;
}

export function useKeyStorage<T>(key: string, defaultValue: any = null): ReturnValue<T> {
    const { data: preference, isLoading } = useGetKeyPreferenceQuery({ key });
    const [setKeyPreference] = useSetKeyPreferenceMutation();

    const handleSetValue = async (value: T) => {
        try {
            await setKeyPreference({
                key,
                value: value
            })
        } catch (error) {
            console.error(error);
        }
    };

    return {
        value: preference ?? defaultValue,
        isLoading,
        setValue: handleSetValue
    }
};
