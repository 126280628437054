import MuiDialogActions from "@mui/material/DialogActions"
import MuiDialogContent from "@mui/material/DialogContent"
import { styled } from '@mui/material/styles';

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    gap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    gap: theme.spacing(1.5),
}))

export const GenericConfirmationDialogStyles = {
    DialogActions,
    DialogContent
}