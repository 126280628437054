import { LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";

export const isIdALenderOnLoanRole = (userId: string, loanRole: LoanRoleDto): boolean => {
    return loanRole.user.id === userId && ([RoleType.LeadLender,
    RoleType.Lender,
    RoleType.Underwriter,
    RoleType.Manager,
    RoleType.AssociateLender
    ]).includes(loanRole.role);
}