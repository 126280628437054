import MuiContainer from "@mui/material/Container"
import { styled } from "@mui/system"

const Container = styled(MuiContainer)(({ theme }) => ({
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div > .MuiPaper-root .MuiPaper-root': {
        marginTop: 0
    },
    '&[data-four-cols="true"]': {
        '&[data-is-focused="false"]': {
            '& > div > .MuiPaper-root': {
                justifyContent: 'center'
            }
        },
    },
    '&[data-is-focused="true"]': {
        '& > div > .MuiPaper-root': {
            minWidth: 'auto',
            '& .loan-card-container': {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: '48%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '32%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '24%',
                },
                display: 'flex',
                alignItems: 'stretch'
            },
        }
    },
    '& > div > .MuiPaper-root': {
        flexShrink: 0,
        flex: 1,
        minWidth: '356px',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        '& .loan-card-container a': {
            display: 'flex',
            alignItems: 'stretch'
        },
        '& .loan-card-container': {

            minHeight: '260px',
            display: 'flex',
            alignItems: 'stretch'
        },
        opacity: 1,
        '&.is-focused': {
            width: '100%'
        },
        '&.is-not-focused': {
            width: '0px',
            padding: 0,
            visibility: 'hidden',
            display: 'none',
            overflow: 'hidden',
            opacity: 0,
        }
    },
}))

export const LoansKanbanStyles = {
    Container
}