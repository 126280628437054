import * as React from "react"

export const AcceptAgreeGraphic = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={73}
        height={72}
        fill="none"
        {...props}
    >
        <path
            fill="#F8F8FA"
            d="M36.5 70.313c18.95 0 34.313-15.363 34.313-34.313 0-18.95-15.363-34.313-34.313-34.313C17.55 1.688 2.187 17.05 2.187 36c0 18.95 15.363 34.313 34.313 34.313Z"
        />
        <path
            fill="#7EB3FF"
            d="M33.8 49.385 19.85 35.491l3.993-3.993 9.957 9.956 17.887-17.888 3.994 3.938L33.8 49.385Z"
        />
    </svg>
)
