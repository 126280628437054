import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.5, 4),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
    }
}))


const ViewButtonGroup = styled(ButtonGroup)(() => ({}))

export const LoanFiltersStyles = {
    Container,
    ViewButtonGroup
}