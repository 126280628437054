import * as React from "react"

export const NavArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4 6.5 4 4 4-4"
    />
  </svg>
)
