import React from "react";
import { LoanListViewColumns } from "src/constants/loan"
import { Loan, LoanTableColumn } from "src/types/loan";
import { formatAsCurrency } from "src/utils/money-formatter";

import { LoanListViewTableStyles } from "./loan-list-view-table.styles";
import { getLoanColumnsWithValues } from "./loan-list-view-table-row.hook";

interface LoanListViewTableTotalRowProps {
    columns: LoanTableColumn[];
    loans: Loan[];
}
export const LoanListViewTableTotalRow = (props: LoanListViewTableTotalRowProps) => {

    return <LoanListViewTableStyles.TableRow>
        <LoanListViewTableStyles.TableCell colSpan={1} data-right="true" data-medium="true">
            TOTAL
        </LoanListViewTableStyles.TableCell>
        {props.columns.map((column) => (
            <LoanListViewTableStyles.TableCell
                align={column.align || 'left'}
                key={column.id} data-bolder="true">
                {column.numeric ? formatAsCurrency(getColumnSum(props.loans, column.id)) : ''}
            </LoanListViewTableStyles.TableCell>
        ))}
    </LoanListViewTableStyles.TableRow>
};

const getColumnSum = (loans: Loan[], columnId: string) => {
    return loans?.reduce((acc, loan) => {
        const columnsWithValues = getLoanColumnsWithValues({ loan, columns: LoanListViewColumns });
        const column = columnsWithValues.find(column => column.id === columnId);
        return acc + Number(column?.value);
    }, 0);
}
