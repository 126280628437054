import { useRouter } from "next/router";
import React from "react";
import { LoanStageCategory } from "src/constants/loan";
import { useCustomizedTableValues } from 'src/contexts/customized-table-context';
import { useKanbanLoans } from "src/contexts/loans-kanban-context";
import { useUser } from "src/hooks/use-user";
import { LoanSortBy } from "src/types/loan";

const LoanSortItems = [
    {
        label: 'Recently added',
        value: LoanSortBy.newest
    },
    {
        label: 'Oldest',
        value: LoanSortBy.oldest
    }
]

export const useLoanFilters = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { state, onLoansDisplayViewChange, onSortLoans } = useKanbanLoans();
    const { query: { phase } } = useRouter();
    const { isLender } = useUser();
    const handleSortByClick = (value: LoanSortBy) => {
        onSortLoans(value);
        setAnchorEl(null);
    };

    const { selectedHeaders, allHeaders, toggleHeader, onReset } = useCustomizedTableValues();

    return {
        onLoansDisplayViewChange,
        dropdownAnchorEl: anchorEl,
        isDropdownOpen: Boolean(anchorEl),
        sortItems: LoanSortItems,
        onDropdownButtonClick: (event) => {
            setAnchorEl(event.currentTarget);
        },
        onDropdownClose: () => {
            setAnchorEl(null);
        },
        onSortByClick: handleSortByClick,
        displayView: state.displayView,
        isLoggedInUserALender: isLender,
        sortBy: state.sortBy,
        isLeadPhase: String(phase).toUpperCase() === LoanStageCategory.LEAD,
        selectedHeaders,
        toggleHeader,
        allHeaders,
        onReset,
    } as const;
};
