import * as React from "react"

export const DragIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10 9.999 3.335 3.332m6.667 6.667 6.666-6.667m-6.666 6.667-6.667 6.666M10.001 10l6.666 6.666M3.334 3.332v3.333m0-3.333h3.333m10 0v3.333m0-3.333h-3.333m-10 13.333v-3.333m0 3.333h3.333m10 0v-3.333m0 3.333h-3.333"
        />
    </svg>
)
